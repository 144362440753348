import React from "react"
import Checkbox from "./checkbox"
const loadText = require("src/utils").loadText
const text = loadText("form")
import { lang } from "../../utils"
import { clone } from "ramda"
const RequiredSuffix = v => {
  return React.createElement("span", {
    children: "*",
    className: "form-plus-required",
    // style: {
    //   fontSize: "24px",
    //   position: "absolute",
    //   right: "16px",
    //   top: "16px",
    //   // transform: "translateY(-50%)",
    //   zIndex: "999",
    //   color: "#0CA8FD",
    //   lineHeight: 1,
    // },
  })
}

const email = {
  type: "item",
  id: "email",
  // customClassName: "xxxx",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.email,
      // suffix: RequiredSuffix,
    },
  },
}
const serviceLocation = {
  type: "item",
  id: "serviceLocation",
  // customClassName: "xxxx",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.serviceLocation,
      // suffix: RequiredSuffix,
    },
  },
}
const phone = {
  type: "item",
  id: "phone",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.phone,
    },
  },
}
const invitedBy = {
  type: "item",
  id: "invitedBy",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: "Invited By",
    },
  },
}
const eventName = {
  type: "item",
  id: "eventName",
  label: "",
  componentType: {
    type: "input",
    params: {
      disabled: true,
      name: text.field.eventName,
    },
  },
}
const location = {
  type: "item",
  id: "location",
  label: "",
  componentType: {
    type: "select",
    params: {
      placeholder: text.field.location,
    },
  },
}
const role = {
  type: "item",
  id: "role",
  label: "",
  componentType: {
    type: "select",
    params: {
      placeholder: "Role",
    },
  },
}
const deployTime = {
  type: "item",
  id: "deployTime",
  label: "",
  componentType: {
    type: "select",
    params: {
      placeholder: text.field.deployTime,
      allowClear: true,
    },
  },
}
const supportProduct = {
  type: "item",
  id: "00N3h00000IJ6xq",
  label: "",
  componentType: {
    type: "select",
    params: {
      placeholder: lang === "en" ? "Product" : "产品",
      allowClear: true,
    },
  },
}

const meetingTime = {
  type: "item",
  id: "meetingTime",
  label: "",
  componentType: {
    type: "datePicker",
    params: {
      showTime: true,
      placeholder: text.field.meetingTime,
      style: { width: "100%", height: 48 },
    },
  },
}
const firstName = {
  type: "item",
  id: "firstName",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.firstName,
      // suffix: RequiredSuffix,
    },
  },
}
const lastName = {
  type: "item",
  id: "lastName",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.lastName,
      // suffix: RequiredSuffix,
    },
  },
}
const name = {
  type: "item",
  id: "name",
  label: "",
  componentType: {
    type: "input",
    params: {
      // name: text.field.name,
      name: lang === "en" ? "Name" : "名称",
      // suffix: RequiredSuffix,
    },
  },
}
const company = {
  type: "item",
  id: "company",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.company,
      // suffix: RequiredSuffix,
    },
  },
}
const website = {
  type: "item",
  id: "website",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.website,
    },
  },
}
const helpMsg = {
  type: "item",
  id: "helpMsg",
  label: "",
  componentType: {
    type: "textarea",
    params: {
      placeholder: text.field.helpMsg,
      style: { height: 120 },
    },
  },
}
const description = {
  type: "item",
  id: "description",
  label: "",
  componentType: {
    type: "textarea",
    params: {
      placeholder: text.field.helpMsg,
      style: { height: 120 },
    },
  },
}
const subject = {
  type: "item",
  id: "subject",
  label: "",
  componentType: {
    type: "textarea",
    params: {
      placeholder: lang === "en" ? "Subject" : "主题",

      style: { height: 120 },
    },
  },
}
const comment = {
  type: "item",
  id: "comment",
  label: "",
  componentType: {
    type: "textarea",
    params: {
      placeholder: text.field.comment,
      style: { height: 120 },
    },
  },
}
const consent = {
  type: "item",
  id: "consent",
  label: "xxx",
  componentType: {
    type: "custom",
    content: () => <div>{text.field.consent}</div>,
  },
}
const job = {
  type: "item",
  id: "job",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.jobTitle,
      // suffix: RequiredSuffix,
    },
  },
}
const interested = {
  type: "item",
  id: "interested",
  label: "",
  componentType: {
    type: "custom",
    content: Checkbox,
  },
}
const details = {
  type: "item",
  id: "details",
  label: "",

  componentType: {
    type: "textarea",
    params: {
      placeholder: "Details",
      style: { width: "100%", height: 120 },
    },
  },
}
const industry = {
  type: "item",
  id: "industry",
  label: "",
  componentType: {
    type: "input",
    params: {
      name: text.field.industry,
      // suffix: RequiredSuffix,
    },
  },
}
const textRequired = val => {
  if (!val) {
    throw text.validation.inputRequired
  }
}
const selectRequired = val => {
  if (!val) {
    throw text.validation.selectRequired
  }
}
export const emailValidator = (val: string) => {
  // if (!val) {
  //   throw text.validation.inputRequired
  // }
  const emailReg = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
  if (!emailReg.test(val)) {
    throw text.validation.invalidEmail
  }
}
const customRule = {
  email: [emailValidator],
  phone: [
    (val: string) => {
      if (!val) {
        return
      }
      // const phoneReg = /^\d*$/
      // if (!phoneReg.test(val)) {
      //   throw text.validation.invalidPhone
      // }
    },
  ],
}
const sourceData = {
  consent: {
    defaultValue: false,
  },
  deployTime: {
    data: (lang === "en"
      ? ["Immediately", "End of month", "End of quarter", "Uncertain"]
      : ["立即部署", "月末", "季末", "不确定"]
    ).map(key => ({
      label: key,
      value: key,
    })),
    // defaultValue: [],
  },
  interested: {
    data: [
      "Cloud Networking",
      "IP Transit",
      "Bare Metal Cloud",
      "Content Delivery Network",
      "上海层峰 Global Accelerator",
      "Edge Data Center Services",
    ].map(key => ({ label: key, value: key })),
  },
  role: {
    data: [
      "VP/Head - Business",
      "VP/Head - IT",
      "Manager - IT/Business",
      "Executive - IT/ Business",
      "Other",
    ].map(key => ({ label: key, value: key })),
  },

  "00N3h00000IJ6xq": {
    data: [
      "上海层峰 IP Transit",
      "上海层峰 Managed Hosting",
      "上海层峰 Edge Computing Network",
      "上海层峰 Colocation",
      "上海层峰 Cloud Networking",
      "上海层峰 DDoS Protection",
      "上海层峰 Bare Metal Cloud",
      "上海层峰-OSS(Portal)",
      "Others",
    ].map(key => ({ label: key, value: key })),
  },
  location: {
    data: (lang === "en"
      ? ["Los Angeles", "San Francisco", "Dallas", "Washington DC", "Online"]
      : ["洛杉矶", "旧金山", "达拉斯", "华盛顿特区", "线上"]
    ).map(key => ({ label: key, value: key })),
  },
}

const generateForm = (
  layout,
  { buttonText, required, submitText, canNotSubmitWhen = undefined }
) => {
  let rule = clone(customRule)
  let res = []
  const requiredMap = new Set(required)
  layout.forEach(row => {
    row.forEach((item, idx) => {
      let newItem = clone(item)
      newItem.customClassName = `col-${24 / row.length} col-idx-${idx}`
      if (requiredMap.has(newItem.id)) {
        const required =
          newItem.componentType.type === "input" ? textRequired : selectRequired
        if (rule[newItem.id]) {
          rule[newItem.id] = [required].concat(rule[newItem.id])
        } else {
          rule[newItem.id] = [required]
        }
        if (newItem.componentType.type === "input") {
          newItem.componentType.params.suffix = RequiredSuffix
        }
      }
      res.push(newItem)
    })
  })
  return {
    canNotSubmitWhen,
    buttonText,
    submitText,
    config: {
      form: res,
      customRule: rule,
      sourceData,
    },
  }
}

const partnerForm = generateForm(
  [
    [email, phone],
    [firstName, lastName],
    [company, website],
    [comment],
    // [consent],
  ],
  {
    buttonText: text.buttonText.partner,
    submitText: text.submitText.partner,
    required: ["email", "firstName", "lastName", "company", "website"],
  }
)
const lpForm = generateForm(
  [[company, email], [firstName, lastName], [job, phone], [interested]],
  {
    buttonText: text.title.consultation,
    submitText: text.submitText.partner,
    required: ["company", "email", "firstName", "lastName", "interested"],
  }
)
const requestForm = generateForm(
  [
    [email, phone],
    [helpMsg],
    [serviceLocation],
    [firstName, lastName],
    [company, website],
    // [consent],
  ],
  {
    required: ["email", "serviceLocation", "firstName", "lastName", "company"],
    buttonText: text.title.request,
    submitText: text.submitText.request,
  }
)
const ebookForm = generateForm(
  [[firstName, lastName], [company, phone], [email]],
  {
    required: ["email", "serviceLocation", "firstName", "lastName", "company"],
    buttonText: text.title.ebook,
    submitText: text.submitText.request,
  }
)
const consultationForm = generateForm(
  [
    [email, phone],
    [deployTime],
    [helpMsg],
    [company, website],
    [firstName, lastName],
    // [consent],
  ],
  {
    buttonText: text.buttonText.consultation,
    submitText: text.submitText.consultation,
    required: [
      "email",
      "firstName",
      "helpMsg",
      "lastName",
      "company",
      "deployTime",
    ],
  }
)

const supportForm = generateForm(
  [
    [email, phone],
    [name],
    [description],
    [supportProduct],
    [subject],
    // [consent],
  ],
  {
    required: ["email", "phone", "name"],
    buttonText: text.title.support,
    submitText: text.submitText.support,
  }
)
const saleForm = generateForm(
  [
    [email, phone],
    [helpMsg],
    [firstName, lastName],
    [company, website],
    // [consent],
  ],
  {
    required: ["email", "firstName", "lastName", "company"],
    buttonText: text.title.sale,
    submitText: text.submitText.sale,
  }
)
const visitForm = generateForm(
  [
    [email, phone],
    [location],
    [firstName, lastName],
    [company, website],
    [comment],
  ],
  {
    required: ["email", "firstName", "lastName", "company", "location"],
    buttonText: text.title.visit,
    submitText: text.submitText.visit,
  }
)
const bookForm = generateForm(
  [
    [email, phone],
    [eventName],
    [firstName, lastName],
    [company, website],
    [comment],
    // [consent],
  ],
  {
    required: ["email", "firstName", "lastName", "company"],
    buttonText: text.title.book,
    submitText: text.submitText.book,
  }
)
const zgaLaunch = generateForm(
  [[firstName, lastName], [company, email], [job, phone], [invitedBy]],
  {
    required: ["email", "firstName", "lastName", "company"],
    buttonText: "Save a Seat",
    submitText: text.submitText.book,
  }
)

const indiaLPForm = generateForm([[name], [company], [role], [email, phone]], {
  required: ["email", "name", "company", "email", "phone"],
  buttonText: text.buttonText.submit,
  submitText: text.submitText.sale,
})
const cookieForm = generateForm(
  [
    [email, phone],
    [firstName, lastName],
    [
      {
        type: "item",
        id: "consent",
        componentType: {
          type: "consent",
          params: {
            label: "Do Not Sell My Personal Information",
          },

          // content: Checkbox,
        },
      },
    ],
  ],
  {
    required: ["email", "firstName", "lastName", "phone"],
    buttonText: text.title.cookie,
    submitText: text.submitText.cookie,
    canNotSubmitWhen: form => form.getField("consent") === false,
  }
)
const formConfig = {
  consultation: consultationForm,
  partner: partnerForm,
  visit: visitForm,
  request: requestForm,
  sale: saleForm,
  cookie: cookieForm,
  support: supportForm,
  book: bookForm,
  lp: lpForm,
  zgaLaunch,
  ebook: ebookForm,
  indiaLp: indiaLPForm,
}
export default formConfig
