import React from "react"
import { Checkbox } from "../../ui"
import "./index.less"
const loadText = require("src/utils").loadText
const text = loadText("form")
interface ItemProps {
  params: any
  form: any
  sourceData: any
  componentConfig: any
  value?: any
  onChange?: (val: any) => never
  _addComIndex?: number
  [key: string]: any
}
const CheckboxGroup: React.FC<ItemProps> = ({
  params,
  sourceData = [],
  componentConfig,
  form,
  onChange,
  value,
}) => {
  return (
    <>
      <p className="form-plus-item-custom-checkbox-title">
        <span>*</span>I am interested in the following services:
      </p>
      <div className="form-plus-item-custom-checkbox">
        {sourceData.map(item => {
          return (
            <Checkbox
              className="checkbox"
              key={item.value}
              size="s"
              checked={value?.some(v => v === item.value)}
              onChange={val => {
                if (!!val) {
                  onChange([...value, item.value])
                } else {
                  onChange([...value].filter(v => v !== item.value))
                }
              }}
              label={item.label}
            />
          )
        })}
      </div>
    </>
  )
}
export default CheckboxGroup
