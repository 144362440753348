import dayjs from "dayjs"
export const lang = process.env.GATSBY_LANG === "zh" ? "zh" : "en"
// export const lang = "zh"
export const loadText = (name: string) => {
  return require(`data/${lang}/${name}.json`)
}
const data = loadText("url")
const subject = loadText("subject")
export const display = (str: string) => dayjs(str).format("MMMM DD, YYYY")
export const makeMap = (arr: string[], value: any) =>
  arr.reduce((acc, cur) => {
    acc[cur] = value
    return acc
  }, {})
export const isMobile =
  typeof window !== "undefined" &&
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
// console.log(isMobile)
export const timeDiff = (start: string, end: string) => {
  let unit = "hour"
  let value = dayjs(end).diff(start, "h")
  if (value > 24) {
    value = dayjs(end).diff(start, "d")
    unit = "day"
  }
  return `${value} ${subject[unit]}${value > 1 && lang === "en" ? "s" : ""}`
}

// const edcsProduct = [
//   "Managed Hosting-PHY",
//   "Cabinet",
//   "Power",
//   "BGP service",
//   "DDoS Protection",
//   "Managed Hosting-VM",
//   "CPU",
//   "IDC Supplies",
//   "Memory",
//   "Professional Service",
//   "Harddrive",
//   "Adapter",
//   "OS",
//   "Fiber",
//   "J-Managed Hosting",
//   "Copper",
//   "Cables",
//   "Cross Connect",
//   // 还没分类
//   "Software and Service",
//   "Equipment",
//   "Leased Line",
//   //
// ]
// const bmcProduct = [
//   "Bare Metal Cloud Bandwidth",
//   "Bare Metal Cloud IP",
//   "Bare Metal Cloud",
//   "Bare Metal Cloud OS",
// ]
// const iptProduct = ["IP transit", "IP Address", "DDoS Bandwidth"]
// const sdnProduct = ["PublicCloudConnect"]
// const sdwanProduct = ["SD-WAN"]
// const ecnProduct = ["Application Acceleration"]

// const map = {
//   ...makeMap(edcsProduct, "edcs"),
//   ...makeMap(bmcProduct, "bmc"),
//   ...makeMap(iptProduct, "ipt"),
//   ...makeMap(sdnProduct, "sdn"),
//   ...makeMap(sdwanProduct, "sdwan"),
//   ...makeMap(ecnProduct, "ecn"),
// }
// const trans = (products: string[]) => {
//   return uniq(
//     products.map(p => {
//       if (!map[p]) {
//       }
//       return map[p]
//     })
//   ).map(key => data[key].name)
// }
// const nameToKey = {
//   "Cloud Networking": "sdn",
//   "Cloud WAN": "sdwan",
//   "Global Intelligent Accelerator": "gia",
//   "Content Delivery Network": "cdn",
//   "Bare Metal Cloud": "bmc",
//   "Edge Data Center Services": "edcs",
//   'IP Transit': 'ipt',
// }

export const handleMapData = data => {
  return data
    .filter(
      ({ code, products, lat, lng }) =>
        lat !== 0 &&
        lng !== 0 &&
        (/[A-Z]{3}[0-9]/.test(code) || products.length > 0)
    )
    .map(d => ({
      ...d,
      products: d.products
        .map(({ nameEn, nameCn }) => (lang === "zh" ? nameCn : nameEn))
        .filter(p => lang === "en" || (lang == "zh" && p !== "CDN")),
    }))
}

export const getProductRelatedPost = (category: string) => `
  allStrapiPosts(
      filter: {
        categories: { elemMatch: { name: { eq: "${category}" } } }
        published: { eq: true }
      }
    ) {
      edges {
        node {
          slug
          wp_date
          thumbnail {
            localFile {
              publicURL
            }
          }
          categories {
            name
          }
          created_at
          content

          title
          visibility
          published
          author {
            slug
            name
          }
          excerpt
        }
      }
    }
`
export const loadScriptToHead = (scriptContent: string) => {
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.appendChild(document.createTextNode(scriptContent))
  document.getElementsByTagName("head")[0].appendChild(script)
}
export const loadScriptSrcAfterBody = (src: string) => {
  return new Promise((resolve, reject) => {
    let script = document.createElement("script")
    script.type = "text/javascript"
    script.src = src
    document.getElementsByTagName("body")[0].appendChild(script)
    script.onload = () => {
      resolve(undefined)
    }
  })
}
export const loadCssLinkToHead = (href: string) => {
  return new Promise((resolve, reject) => {
    let link = document.createElement("link")
    link.rel = "stylesheet"
    link.href = href
    link.type = "text/css"
    document.getElementsByTagName("head")[0].appendChild(link)
    link.onload = (e: any) => {
      resolve(undefined)
    }
  })
}
