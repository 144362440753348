import React, { useState } from "react"
import formConfig, { emailValidator } from "./config"
import { Button, Modal } from "../ui"
import FormPlus from "../ui/formplus"
import { sendData } from "../form-biz"
import "./index.less"
const loadText = require("src/utils").loadText
const text = loadText("form")
const CustomSubmit = ({
  canSubmit,
  canNotSubmitWhen,
  onSubmit,
  data,
  submitLabel,
  submitMsg,
  onSuccess,
  source,
  onSuccessWithoutModal,
}: {
  // form?: any
  canSubmit?: any
  onSubmit: any
  canNotSubmitWhen?: Function
  submitLabel: string
  submitMsg: string
  onSuccess?: Function
  source?: string
  data?: any
  onSuccessWithoutModal?: Function
}) => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const submit = async () => {
    const ok = canSubmit()
    console.log(ok, data)
    if (ok) {
      setButtonLoading(true)
      try {
        await onSubmit(data)
        if (onSuccessWithoutModal) {
          onSuccessWithoutModal()
        } else {
          setOpen(true)
        }
      } catch (e) {}
    }
    setButtonLoading(false)
  }
  const [open, setOpen] = useState(false)
  // useEffect(() => {
  //   setButtonDisabled(!form.getField("consent"))
  // }, [form.getField("consent")])
  return (
    <div>
      <Modal
        open={open}
        width={912}
        onClose={() => {
          setOpen(false)
          if (onSuccess) {
            onSuccess()
          }
          // onClose();
        }}
      >
        <div className="success-modal">
          {source === "gaming" && (
            <img
              height="1"
              width="1"
              style={{ display: "none" }}
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=3056652&conversionId=4056516&fmt=gif"
            />
          )}
          <div className="success-modal-icon">
            <img src="/check.svg" alt="success-icon" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: submitMsg }}
            className="font-20 mt-32 success-modal-content"
          />
        </div>
      </Modal>
      <Button
        // disabled={canNotSubmitWhen ? canNotSubmitWhen(form) : false}
        size="l"
        onClick={submit}
        width="78%"
        style={{
          margin: "auto",
          display: "block",
        }}
        loading={buttonLoading}
      >
        {submitLabel}
      </Button>
    </div>
  )
}
interface FormProps {
  onSubmit: (ok: any, data: any) => Promise<void>
  type:
    | "customer"
    | "partner"
    | "rhs"
    | "sale"
    | "visit"
    | "consultation"
    | "lp"
    | "ebook"
    | "indiaLp"
    | "zgaLaunch"
  onSuccess?: Function
  defaultData?: any
  showConsent?: boolean
  source?: string
  onSuccessWithoutModal?: Function
}
const Form: React.FC<FormProps> = ({
  onSubmit,
  type,
  source,
  onSuccess,
  defaultData,
  children,
  onSuccessWithoutModal,
  showConsent = true,
}) => {
  let { config, buttonText, submitText, canNotSubmitWhen } = formConfig[type]

  return (
    <div style={{ width: "100%" }}>
      <FormPlus
        defaultData={defaultData}
        config={config}
        submit={
          <CustomSubmit
            canNotSubmitWhen={canNotSubmitWhen}
            onSubmit={onSubmit}
            submitLabel={buttonText}
            onSuccessWithoutModal={onSuccessWithoutModal}
            submitMsg={submitText}
            source={source}
            onSuccess={onSuccess}
          />
        }
      >
        {children}
        {showConsent && (
          <div
            className="form-consent"
            style={{
              color: "#A7B1C1",
              fontSize: "12px",
              lineHeight: "16px",
              marginBottom: "48px",
              fontWeight: 400,
            }}
          >
            {text.field.consent}
          </div>
        )}
      </FormPlus>
    </div>
  )
}
export default Form

export const useEmailForm = ({ source }) => {
  const [val, setV] = useState("")
  const [error, setE] = useState("")
  const [canSubmit, setS] = useState(false)
  const [open, setOpen] = useState(false)
  const [loading, setL] = useState(false)
  const onChange = val => {
    let error = ""
    setV(val)
    try {
      emailValidator(val)
    } catch (e) {
      error = e
    }
    setS(error === "")
    setE(error)
  }
  const onSubmit = async () => {
    try {
      setL(true)
      await sendData({
        data: { email: val },
        url: text.handler.subscribe,
        source,
      })
      setOpen(true)
    } catch (e) {}
    setL(false)
  }
  const ButtonWrap = ({ children }) => (
    <div>
      <Modal
        open={open}
        width={912}
        onClose={() => {
          setOpen(false)
        }}
      >
        <div className="success-modal">
          <div className="success-modal-icon">
            <img src="/check.svg" alt="success-icon" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: text.submitText.insider }}
            className="font-20 mt-32 success-modal-content"
          />
        </div>
      </Modal>
      {children}
    </div>
  )
  return { val, onChange, error, canSubmit, ButtonWrap, onSubmit, loading }
}
